import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ModalForo from './ModalForo'


const ForoCard = (props) => {
    const [viewForo, setViewForo] = useState(false)
    const modalToggle = () => setViewForo(!viewForo)
    
    console.log(props.comments)
    return(
        <section d-flex="true" flex-wrap="true">
            <Card className='foro-cards'>
                <Card.Body>
                    <Container fluid>
                        <Row>
                            <Col sm={4}><img width="100%" height="160px" variant="top" src={props.image} /><h6>{props.name}</h6></Col>
                            <Col sm={8}>
                                <Row><Col><p style={{width:"30fr"}} placeholder="Comentario">{props.comments[0].comment}</p></Col></Row>
                                <Row><Col><p style={{width:"30fr"}} placeholder="Comentario">{props.comments[1].comment}</p></Col></Row>
                                <Row><Button onClick={modalToggle}>Mas información</Button></Row>
                                <ModalForo show={viewForo} onHide={modalToggle} name={props.name} image={props.image} comments={props.comments}/>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
        </section>
    );
}
export default ForoCard;