import React from 'react';
import { FiLogOut } from 'react-icons/fi';


export default function Profile({ photoURL, logout }) {

    return (
        <div className="btn-group dropleft">
            <button className="photo-login" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img className='photo' src={photoURL}></img>
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button className='dropdown-item' type="button" >Configuración</button>
                <button className='dropdown-item' type="button" >Mi Calendario</button>
                <button className='dropdown-item' type="button" onClick={logout}><FiLogOut /></button>
            </div>
        </div>
    )
}



