import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Calendar from 'react-calendar'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
const RegCalendarTime = ({ show, onHide, name2, typeGo }) => {
    const [date, setDate] = useState(new Date())
    const [cont, setCont] = useState(0);
    useEffect(() => {
        axios.get(`https://us-central1-aglomer-9c6d9.cloudfunctions.net/place/${name2}`)
            .then(res => {
                const dataPlace = res.data
		if(typeGo==='go.cont'){
                setCont(dataPlace.go.cont);
		}
		else{
		setCont(dataPlace.maybe.cont);
		}
            })
    }, [])
    const changeDate = (dateP) => {
        setDate(dateP)
	const  contJson={
	typeGo:cont
	}
        axios.put(`https://us-central1-aglomer-9c6d9.cloudfunctions.net/place/${name2}`, contJson)
    }
    const contMore = () => {
        setCont(cont + 1);
	alert("Tu asistencia quedo registrada")
    }

    return (
        <Modal show={show} onHide={onHide} animation={false}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <Calendar onChange={changeDate} value={date} />
                {console.log(cont)}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={contMore}>Confirmar</Button>
            </Modal.Footer>
        </Modal>
    )
}
export default RegCalendarTime;
