import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import RegCalendar from './RegCalendarTime'
import MapLaces from '../ComponentsMap/Maps'
import ModalForoP from '../componentsForo/ModalForo'

const ModalCard = (props) => {
    const [go, setGo] = useState(false);
    const [maybe, setMaybe] = useState(false);
    const [onOff, setOnOff]= useState(false);
	
	const toggleForo=()=>{
	setOnOff(!onOff);	
}	
    const toggleGo = () => {
        setGo(!go);
    }
    const toggleMaybe = () =>{
	setMaybe(!maybe);
	}
	const style={
	marginLeft:"20px",
	paddingLeft: "20px"		
}

const handleClickCont=()=>{
alert("la cantidad de personas en este sitio es de: " + props.cont2)
}
    return (
        <Modal show={props.show} onHide={props.onHide}  size="xl" aria-labelledby="contained-modal-title-vcenter" centered animation={false}>
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row xs={12} md={12} lg={12}>
                        <Col xs={5} md={5} lg={5}>
                            <img width="331px" height="270px" variant="top" src={props.image1} style={style} />
                            <h1 style={style}>{props.name1}</h1>
                            <p style={style}>{props.descriptionsP}</p>
                        </Col>
                        <Col>
                            <Row xs={12}>
				<Col>
				<MapLaces bool={false} lat={props.lat3} lng={props.lng3} zn={15} typeClass="map-target"/>
				</Col>
                                <Col>
                                    {props.promotionsP.map(((arr, ind) => {
                                        return (
                                            <ul key={ind}><li> {arr.namepromotions1} {arr.descriptionsPromotion}
						
							<br/>tarifas: antes {arr.value_before} 
						       : ahora {arr.value_after}
						</li>
                                            </ul>
                                        )
                                    }))
                                    }
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col className="text-center">
                                    <Button onClick={handleClickCont}>Cuantas personas estan aquí!</Button>
                                </Col>
                            </Row>
                            <br />
                            <Row className="text-center">
                                <Col>
                                    <Button onClick={toggleGo}>Asistire</Button>
                                    <RegCalendar show={go} onHide={toggleGo} name2={props.name1} typeGo='go.cont' />
                                </Col>
                                <Col>
                                    <Button onClick={toggleMaybe}>Tal vez</Button>
				    <RegCalendar show={maybe} onHide={toggleMaybe} name2={props.name1} typeGo='maybe.cont' />	
                                </Col>
                               {/* <Col>
                                    <Button>No</Button>
                                </Col>*/}
                            </Row>
                            <br />
                            <Row>
                                <Col className="text-center">
                                    <Button onClick={toggleForo}>Ver comentarios</Button>
					<ModalForoP show={onOff} onHide={toggleForo} image={props.image1} comments={props.comments1}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                Sitios recomendados
        </Modal.Footer>
        </Modal>
    )
}
export default ModalCard;
